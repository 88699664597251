<template>
  <div class="bgc">
    <div class="downlaod" @click="download">
      <img src="../assets/img/mchDownload.png" alt="" />
    </div>
  </div>
</template>

<script>
import { getMchDownUrl } from "../api/index";
export default {
  name: "mchDownload",
  data() {
    return {
      andriod: "",
    };
  },
  created() {
    this.getDownloadUrl();
  },
  methods: {
    async getDownloadUrl() {
      const res = await getMchDownUrl();
      console.log(res);
      if (res.data.code != 200) return;
      this.andriod = res.data.data.andriod_url;
      console.log(this.andriod);
    },
    download() {
      window.location.href = this.andriod;
    },
  },
};
</script>

<style lang="scss" scoped>
.bgc {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/img/mch.png");
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  .downlaod {
    margin-bottom: 0.9rem;
    img {
      height: 1.1rem;
    }
  }
}
</style>
